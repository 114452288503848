import React from 'react';
import ProjectCard from '../components/ProjectCard';
import { projects } from '../data/projects';

const Home = () => {
  return (
    <section className="container mx-auto p-6">
      
      <h1 className="text-3xl font-bold mb-6 mt-10">Sobre Nosotros</h1>
      <div className='p-4 mb-8'>
        <p className='mb-4'>Neerutech es un emprendimiento que tiene como misión ayudar en la digitalización de los negocios, optimización del flujo de trabajo y administración de los recursos y usuarios.</p>
        <p>Somos expertos en creación de sitios web. Trabajamos con distintas herramientas y plataformas como WordPress, Wix y Shopify y también manejamos lenguajes y framewoks de programación y base de datos, como React, Gatsby, JavaScript, Bootstrap, MongoDB, entre otros.</p>
      </div>
       {/* Contenido de la página */}
      <h1 className="text-3xl font-bold mb-6">Nuestros Proyectos</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative">
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>

      
    </section>
  );
};

export default Home;
