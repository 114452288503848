import React from 'react';
// import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-neerutechFooter text-white p-4">
      <div className="container mx-auto flex justify-center">
        <p>&copy; {new Date().getFullYear()} Neerutech. Todos los derechos reservados.</p>
        {/* <div className="space-x-4">
          <Link to="/about" className="hover:text-gray-400">Quiénes Somos</Link>
          <Link to="/contact" className="hover:text-gray-400">Contacto</Link>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
