import React from 'react';

const ProjectCard = ({ project }) => {
  return (
    <div className="bg-white border p-4 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">

        {/* Añadimos clases para controlar el tamaño de la imagen */}
        <img 
            src={project.image} 
            alt={project.title} 
            className="w-full h-72 lg:h-80 object-cover object-top rounded-t-lg mb-4" 
        />
        
        <h2 className="text-xl font-bold">{project.title}</h2>
        <p className="text-gray-600 mt-2">{project.description}</p>

        {/* Renderización de los Chips */}
        <div className="flex flex-wrap gap-2 mt-3">
            {project.technologies.map((tech, index) => (
            <span 
                key={index} 
                className="bg-blue-100 text-blue-800 text-sm font-semibold px-2.5 py-0.5 rounded"
            >
                {tech}
            </span>
            ))}
        </div>

        <a href={project.link} target="_blank" rel="noopener noreferrer" className="inline-block mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Ver Proyecto
        </a>
    </div>
  );
};

export default ProjectCard;
