import React from 'react';

const About = () => {
  return (
    <section className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Sobre mí</h1>
      <p className="text-lg leading-relaxed">
        Soy un desarrollador web con experiencia en plataformas como WordPress, Shopify, Wix, 
        y en desarrollo personalizado usando React, Node.js, y MongoDB. Mi objetivo es 
        ayudar a las empresas a tener una presencia en línea moderna y funcional.
      </p>
      <p className="text-lg leading-relaxed mt-4">
        Neerutech es mi emprendimiento, donde brindamos soluciones de diseño web, mantenimiento, 
        y desarrollo de sistemas personalizados. Siempre estoy aprendiendo nuevas tecnologías y 
        mejorando mis habilidades para ofrecer el mejor servicio posible a mis clientes.
      </p>
    </section>
  );
};

export default About;
