export const projects = [
    {
        title: 'De Alma a Alma',
        description: 'Sitio web de Carmen Cecilia Rodriguez, brillante psicóloga que ofrece múltiples servicios.',
        image: '/assets/images/dealmaalma_desktop.jpg', // Aquí pondrás la imagen del mockup
        link: 'https://dealmaalma.com/',
        category: 'WordPress',
        technologies: ['Wordpress', 'HTML', 'CSS'] // Array de tecnologías
    },
    {
        title: 'Lunitas y Duendes',
        description: 'Tienda online de productos para bebés y niños, desarrollada en Shopify.',
        image: '/assets/images/lunitas_desktop.jpg', // Aquí pondrás la imagen del mockup
        link: 'https://www.lunitasyduendes.com',
        category: 'Shopify',
        technologies: ['Shopify', 'HTML', 'CSS'] // Array de tecnologías
    },
    {
        title: 'NeeruTech',
        description: 'Optimiza tu éxito en línea: creación de sitios web, análisis basado en datos y mucha más.',
        image: '/assets/images/neerutech_desktop.jpg',
        link: 'https://www.neerutech.com',
        category: 'WordPress',
        technologies: ['Wix', 'HTML', 'CSS'] // Array de tecnologías
    },
    {
        title: 'Personal Brand Mockup',
        description: 'Sitio Web para mostrar tu marca personal, skills y proyectos. Se puede usar para promocionarse y para que contacten contigo.',
        image: '/assets/images/personal_brand_desktop.jpg',
        link: 'https://gfranzella.github.io/personal_brand/index.html',
        category: 'HTML',
        technologies: ['HTML', 'CSS', 'JavaScript', 'Bootstrap'] // Array de tecnologías
    },
    {
        title: 'Plantilla de Web de Nutrición',
        description: 'Plantilla de sitio web one-pager para mostrar una marca personal para nutricionista.',
        image: '/assets/images/nutricion_desktop.jpg',
        link: 'https://gfranzella.github.io/nutrition-template-page/',
        category: 'HTML',
        technologies: ['HTML', 'CSS', 'JavaScript', 'Bootstrap'] // Array de tecnologías
    },
    {
        title: 'Restarante The Lobstar',
        description: 'Página para el restaurante The Lobstar, para permitir a los clientes visitar, mirar la carta, contactar y reservar.',
        image: '/assets/images/lobstar_desktop.jpg',
        link: 'https://thelobstar-1d345.web.app/',
        category: 'HTML',
        technologies: ['React', 'Gatsby', 'Bootstrap', 'CSS'] // Array de tecnologías
    },
    // Más proyectos
  ];
  