import React from 'react';

const Contact = () => {
  return (
    <section className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Contacto</h1>
      <p className="text-lg mb-6">
        Si tienes alguna pregunta o deseas trabajar conmigo, no dudes en contactarme a través del siguiente formulario o por correo electrónico.
      </p>
      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nombre</label>
          <input type="text" className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Tu nombre" />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Correo electrónico</label>
          <input type="email" className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Tu correo electrónico" />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Mensaje</label>
          <textarea className="mt-1 block w-full p-2 border border-gray-300 rounded-md" rows="4" placeholder="Tu mensaje"></textarea>
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Enviar
        </button>
      </form>
    </section>
  );
};

export default Contact;
